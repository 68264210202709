import { AnnotationType } from '@tabeeb/enums'

import { BoxGeometry, CylinderGeometry, Euler, Matrix4, Mesh, Vector3, TubeGeometry, CatmullRomCurve3 } from 'three'

import { computeBoundsTree } from 'three-mesh-bvh'

const convertAnnotationToMesh = (annotation) => {
  if (![AnnotationType.Box, AnnotationType.Cylinder, AnnotationType.Polyline].includes(annotation.Type)) {
    return null
  }

  let position = new Vector3(0, 0, 0)
  let rotation = new Euler(0, 0, 0)
  let scale = new Vector3(1, 1, 1)

  let geometry = null
  const geometryOffset = new Vector3(0, 0, 0)

  if (annotation.Type === AnnotationType.Box) {
    position = new Vector3(annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z)
    rotation = new Euler(annotation.Rotation.X, annotation.Rotation.Y, annotation.Rotation.Z)
    scale = new Vector3(annotation.Scale.X, annotation.Scale.Y, annotation.Scale.Z)

    geometry = new BoxGeometry(1, 1, 1)
    geometryOffset.set(0.5, 0.5, 0.5)
  }

  if (annotation.Type === AnnotationType.Cylinder) {
    position = new Vector3(annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z)
    rotation = new Euler(annotation.Rotation.X, annotation.Rotation.Y, annotation.Rotation.Z)
    scale = new Vector3(annotation.Scale.X, annotation.Scale.Y, annotation.Scale.Z)

    geometry = new CylinderGeometry(0.5, 0.5, 1, 36)
    geometryOffset.set(0, 0.5, 0)
  }

  if (annotation.Type === AnnotationType.Polyline) {
    const curve = new CatmullRomCurve3(annotation.Points.map((point) => new Vector3(point.X, point.Y, point.Z)))

    geometry = new TubeGeometry(curve, 64, 0.01)
  }

  geometryOffset.multiply(scale)
  geometryOffset.applyEuler(rotation)
  position.add(geometryOffset)

  const mesh = new Mesh(geometry)
  mesh.position.copy(position)
  mesh.scale.copy(scale)
  mesh.setRotationFromEuler(rotation)
  mesh.updateMatrixWorld()

  mesh.geometry.computeBoundsTree = computeBoundsTree
  mesh.geometry.computeBoundsTree()

  return mesh
}

export function intersects(firstAnnotation, secondAnnotation) {
  const mesh1 = convertAnnotationToMesh(firstAnnotation)
  const mesh2 = convertAnnotationToMesh(secondAnnotation)

  if (!mesh1 || !mesh2) {
    return false
  }

  const transformMatrix = new Matrix4().copy(mesh1.matrixWorld).invert().multiply(mesh2.matrixWorld)

  return mesh1.geometry.boundsTree.intersectsGeometry(mesh2.geometry, transformMatrix)
}
